<template>
    <a-card>
        <a-page-header
            title='任务详情'
        />
        <div style="margin-bottom: 20px;">
            <base-button
                :title="'返回'"
                @onClickBtn="handleBack"
            ></base-button>
        </div>
        <a-form-model
            :model="dataForm"
            v-bind='layout'
        >
            <a-form-model-item label="项目名称">
                {{ dataForm.projectName || '-' }}
            </a-form-model-item>
            <a-form-model-item label="项目编码">
                {{ dataForm.projectCode || '-' }}
            </a-form-model-item>
            <a-form-model-item label="项目品牌">
                {{ dataForm.projectPrincipalName || '-' }}
            </a-form-model-item>
            <a-form-model-item label="任务KPI类型">
                {{ dataForm.kpiType == 1 ? '车系' : '-' }}
            </a-form-model-item>
            <a-form-model-item label="广告平台">
                {{ filterLabel(dataForm.adPlatformType) }}
            </a-form-model-item>
            <a-form-model-item label="任务名称">
                {{ dataForm.missionName || '-' }}
            </a-form-model-item>
            <a-form-model-item label="投放结构">
                {{ dataForm.deliveryStruct == 1 ? '单车系投放A' : '-' }}
            </a-form-model-item>
            <a-form-model-item label="任务编码">
                {{ dataForm.missionCode || '-' }}
            </a-form-model-item>
            <a-form-model-item label="任务周期">
                {{ filterDay(dataForm.startTime) }} ~ {{ filterDay(dataForm.endTime) }}
            </a-form-model-item>
            <a-form-model-item label="任务总预算">
                {{ formatUnit(dataForm.totalBudget) }}
            </a-form-model-item>
            <a-form-model-item label="任务日预算">
                {{ formatUnit(dataForm.dailyBudget) }}
            </a-form-model-item>
            <a-form-model-item label="任务总KPI">
                {{ dataForm.totalKpi }}
            </a-form-model-item>
            <a-form-model-item label=" " :colon="false">
                <a-table
                    :columns="carColumns"
                    :data-source="dataForm.carSeriesRelationList"
                    :row-key="(record, index) => index"
                    :pagination='false'
                    bordered
                />
            </a-form-model-item>
            <a-form-model-item label="投放位置" prop="placementArr">
                <a-checkbox-group v-model="dataForm.placementArr" :options="placementOptions" disabled />
            </a-form-model-item>
            <a-form-model-item label="集客场景" prop="customerSceneArr">
                <a-checkbox-group v-model="dataForm.customerSceneArr" :options="customerSceneList" disabled />
            </a-form-model-item>
            <a-form-model-item label="出价类型" prop="bidTypeArr">
                <a-checkbox-group v-model="dataForm.bidTypeArr" :options="bidTypeOptions" disabled />
            </a-form-model-item>
            <a-form-model-item label=" " :colon="false">
                <DistrictSelection
                    :cur-value="districtCurValue"
                    :extensions="districtExtensions"
                    @receive="districtReceive"
                />
            </a-form-model-item>

            <a-form-model-item label="自定义定向人群" :wrapperCol="{span: 19}">
                <a-radio-group v-model="dataForm.includeCrowd" disabled>
                    <a-radio :value="1">不限</a-radio>
                    <a-radio :value="2">
                        选择选择数据源（多选）
                        <a-select
                            mode="multiple"
                            v-model="(dataForm.dyUserTarget || {}).include_crowd_pack_id_arr"
                            placeholder="请选择"
                            disabled
                        >
                            <a-select-option
                                v-for="item in crowdList"
                                :key="item.id"
                            >
                                {{ item.sourceName }}
                            </a-select-option>
                        </a-select>
                    </a-radio>
                </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="自定义排除人群" :wrapperCol="{span: 19}">
                <a-radio-group v-model="dataForm.excludeCrowd" disabled>
                    <a-radio :value="1">不限</a-radio>
                    <a-radio :value="2">
                        选择选择数据源（多选）
                        <a-select
                            mode="multiple"
                            v-model="(dataForm.dyUserTarget || {}).exclude_crowd_pack_id_arr"
                            placeholder="请选择"
                            disabled
                        >
                            <a-select-option
                                v-for="item in crowdList"
                                :key="item.id"
                            >
                                {{ item.sourceName }}
                            </a-select-option>
                        </a-select>
                    </a-radio>
                </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label=" " :colon="false">
                <AudienceTarget
                    :cur-value="audienceCurValue"
                    :extensions="audienceExtensions"
                    @receive="audienceReceive"
                />
            </a-form-model-item>
            <a-form-model-item label="任务关联的广告主">
                <a-table
                    :columns="adColumns"
                    :data-source="dataForm.missionAdvertiserRelationList"
                    :row-key="(record, index) => index"
                    :pagination='false'
                    bordered
                />
            </a-form-model-item>
        </a-form-model>
    </a-card>
</template>

<script>
import { DistrictSelection, AudienceTarget } from '@bytedance-ad/mui-vue2';
import dayjs from 'dayjs';

export default {
    components: {
        DistrictSelection,
        AudienceTarget,
    },
    data() {
        return {
            id: this.$route.query.id,
            dataForm: {
                dyUserTarget: {
                    include_crowd_pack_id_arr: [],
                    exclude_crowd_pack_id_arr: []
                },
                includeCrowd: 1,
                excludeCrowd: 1,
            },
            layout: {
                labelCol: { span: 3 },
                wrapperCol: { span: 20 },
            },
            carColumns: [
                {
                    align: 'center',
                    title: '品牌',
                    dataIndex: 'principalName',
                    width: '20%',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '车系',
                    dataIndex: 'carSeriesName',
                    width: '20%',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '任务明细KPI',
                    dataIndex: 'detailKpi',
                    width: '20%',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '计划目标出价（元）',
                    dataIndex: 'targetBid',
                    width: '20%',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '计划日预算（元）',
                    dataIndex: 'dailyBudget',
                    width: '20%',
                    customRender(text) {
                        return text || '-'
                    }
                },
            ],
            adColumns: [
                {
                    align: 'center',
                    title: '广告主',
                    dataIndex: 'advertiserName',
                    width: '33.33%',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '品牌',
                    dataIndex: 'principalName',
                    width: '33.33%',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '平台帐套',
                    dataIndex: 'adPlatformAccountName',
                    width: '33.33%',
                    customRender(text) {
                        return text || '-'
                    }
                },
            ],
            placementOptions: [
                { label: '抖音', value: 1 },
                { label: '今日头条', value: 2, disabled: true },
                { label: '西瓜视频', value: 3, disabled: true },
            ],
            customerSceneList: [],
            bidTypeOptions: [
                { label: '常规投放', value: 1 },
                { label: '放量投放', value: 2 },
                { label: '最大转化投放', value: 3 },
            ],
            districtCurValue: { 
                district: "REGION",
            },
            districtExtensions: {
                isV1: true,
            },
            audienceCurValue: {
                age: ["AGE_BETWEEN_18_23", "AGE_BETWEEN_24_30", "AGE_BETWEEN_31_40", "AGE_BETWEEN_41_49", "AGE_ABOVE_50"],
                interest_action_mode: "RECOMMEND",
            },
            audienceExtensions: {
                hiddenComponents: [ //隐藏项
                    'district', // 地域
                    'crowd', //人群
                    'superior',
                    'platform', //平台
                    'device', //设备
                    'network', //网络
                    'hide_is_exists', // 已安装用户
                    'transform', //过滤已转化用户
                    // 'transform_time',
                    'mobile_brand', //手机品牌
                    'mobile_price', //手机价格
                    'smart', //智能放量
                ]
            },
            crowdList: [],
        }
    },
    created() {
        this.getCustomerScene()
        this.getAdPlatformList()
        this.getDetail()
        this.getAudienceDataSourceList()
    },
    methods: {
        handleBack() {
            this.$router.push({
                path: '/taskManage'
            })
        },
        // 获取人群
        getAudienceDataSourceList() {
            let params = {
                page: 1,
                size: 1000
            }
            this.$api.core.materialManage.getAudienceDataSourceList(params).then(res => {
                if(res.code == 200) {
                    this.crowdList = res.data
                } else {
                    console.error(`获取人群列表失败，${res.message}`)
                }
            })
        },
        // 获取广告平台
        getAdPlatformList() {
            this.$api.core.pphtManage.getAdPlatformList().then(res => {
                if(res.code == 200) {
                    this.platformList = res.data
                } else {
                    console.error(`获取平台列表失败，${res.message}`)
                }
            })
        },
        // 获取集客场景
        getCustomerScene() {
            this.$api.core.materialManage.getCustomerScene().then(res => {
                if(res.code == 200) {
                    this.customerSceneList = res.data
                    this.customerSceneList.map(item => {
                        item.label = item.desc
                        item.value = item.code
                    })
                } else {
                    console.error(`获取集客场景失败，${res}`)
                }
            })
        },
        filterDay(day) {
            return day ? dayjs(day).format('YYYY/MM/DD') : '-'
        },
        // 返回文本
        filterLabel(id) {
            let txt = '-'
            if(id) {
                txt = this.platformList.filter(item => item.id == id)[0].adPlatformName
            }
            return txt
        },
        // 单位转化为万，保留4位数
        formatUnit(value = 0) {
            let val = Number(value)
            return (val/1e4).toFixed(4) + '万'
        },
        // 地域定向
        districtReceive(value) {
            console.log('districtReceive', {value})
            this.districtCurValue = value
        },
        // 人群定向
        audienceReceive(value) {
            console.log('audienceReceive', {value})
            this.audienceCurValue = value
        },
        //获取详情
        getDetail() {
            this.$api.core.deliverManage.getTaskDetail(this.id).then((res) => {
                if(res.code == 200) {
                    let _data = res.data

                    this.dataForm = _data

                    if((_data.dyUserTarget.include_crowd_pack_id_arr || []).length > 0) {
                        this.dataForm.includeCrowd = 2
                    } else {
                        this.dataForm.includeCrowd = 1
                        this.dataForm.dyUserTarget.include_crowd_pack_id_arr = []
                    }

                    if((_data.dyUserTarget.exclude_crowd_pack_id_arr || []).length > 0) {
                        this.dataForm.excludeCrowd = 2
                    } else {
                        this.dataForm.excludeCrowd = 1
                        this.dataForm.dyUserTarget.exclude_crowd_pack_id_arr = []
                    }

                    this.districtReceive(_data.dyUserTarget || {})
                    this.audienceReceive(_data.dyUserTarget || {})
                } else {
                    this.$message.error(`获取详情失败，${res.message}`)
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
/deep/ .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    display: flex;
    align-items: center;
    justify-content: center;
    .ant-collapse-arrow {
        position: static;
        transform: translateY(0);
    }
}
/deep/ .right-panel .header {
    display: block;
    padding: 0;
    line-height: unset;
}
/deep/ .right-panel-content .header {
    display: flex;
    align-items: center;
    padding: 2px 12px;
    background-color: transparent;
    line-height: unset;
}
</style>